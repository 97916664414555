import React from 'react';
import SectionHeader from '../components/sections/partials/SectionHeader';
import classNames from 'classnames';
import Button from '../components/elements/Button';

export default function Pricing(props) {
  const {
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  } = props;

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={{ title: 'Pricing', paragraph: 'We also offer customized packages' }} className="center-content" />

          <div className="price-card reveal-from-bottom" data-reveal-delay="200">
            <div>
              <h2>$15,000</h2>
              <p>Per trained developer</p>
            </div>
            <div>
              <Button tag="a" color="primary" wideMobile href="/training">Learn More</Button>
            </div>
          </div>

          <div className="price-card reveal-from-bottom" data-reveal-delay="400">
            <div>
              <h2>$5,000</h2>
              <p>Per product developed through our course</p>
            </div>
            <div>
              <Button tag="a" wideMobile href="/products">Products</Button>
            </div>
          </div>

          <div className="price-card reveal-from-bottom" data-reveal-delay="600">
            <div>
              <h2>$500/month</h2>
              <p>For continuing guidance and mentorship</p>
            </div>
            <div>
              <Button tag="a" wideMobile href="/perks">Perks</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
