import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

import LayoutDefault from './layouts/LayoutDefault';
import Home from './views/Home';
import Test from './views/Test';
import SignUp from './views/SignUp';
import Documentation from './views/Documentation';
import Pricing from './views/Pricing';
import ResetPassword from './views/ResetPassword';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/test" component={Test} layout={LayoutDefault} />
          <AppRoute exact path="/signup" component={SignUp} layout={LayoutDefault} />
          <AppRoute exact path="/login" component={Login} layout={LayoutDefault} />
          <AppRoute exact path="/documentation" component={Documentation} layout={LayoutDefault} />
          <AppRoute exact path="/pricing" component={Pricing} layout={LayoutDefault} />
          <AppRoute exact path="/reset-password" component={ResetPassword} layout={LayoutDefault} />
          <AppRoute exact path="/forgot-password" component={ForgotPassword} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;
