import React, { useCallback, useState } from 'react';
import { login } from '../business/api';
import { redirectToEntityDashboard } from '../business/entityRedirection';
import Button from '../components/elements/Button';
import ButtonGroup from '../components/elements/ButtonGroup';
import FormLabel from '../components/elements/FormLabel';
import GoogleSignIn from '../components/elements/GoogleSignIn';
import Input from '../components/elements/Input';
import OrBar from '../components/elements/OrBar';
import memoizeEnterPress from '../utils/memoizeEnterPress';

export default function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [resultMessage, setResultMessage] = useState('');
  const [success, setSuccess] = useState(null);

  const submitSignin = useCallback(async () => {
    const response = await login({ email, password });

    if(response.status >= 200 && response.status < 400) {
      setSuccess(true);

      try {
        const { token, entity_type, entity } = response?.json;
        redirectToEntityDashboard({ token, entity_type, entity });
      } catch (err) {
        setResultMessage('Invalid response from server. ' + response.text);
      }
      // redirect to the relevant dashboard for the given user
      // administrator: admin.staticcast.com
      // affiliate: affiliate.staticcast.com
      // manager: manager.staticcast.com
      // user: app.staticcast.com

    } else {
      setSuccess(false);
      setResultMessage(response.text);
    }
  }, [email, password]);

  const enterHandler = memoizeEnterPress(submitSignin);

  if (success === true) {
    return (
      <section className="hero section center-content">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100 }}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{ opacity: 1 }}>
            Signed In
          </h1>
          <p>Redirecting, please wait</p>

          <Button tag="a" color="primary" wideMobile href="/">
            Return Home
          </Button>
        </div>
      </section>
    );
  }

  if (success === false) {
    return (
      <section className="hero section center-content">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100 }}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{ opacity: 1 }}>
            Login Failure!
          </h1>
          <p>{resultMessage}</p>
          <Button onClick={() => {
            setSuccess(null);
            setResultMessage('');
          }}>Try Again</Button>
        </div>
      </section>
    );
  }

  return (
    <section key="login-section" className="hero section center-content">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h1 id="login-title" className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{ opacity: 1 }}>
          User <span className="text-color-primary">Login</span>
        </h1>
        <form className="med-form" style={{ padding: 20 }} data-reveal-delay="400">
          <div className="input-segment">
            <FormLabel>Email Address</FormLabel>
            <Input onKeyUp={enterHandler('email')} value={email} onChange={event => setEmail(event?.target?.value)} type="email" placeholder="Email" />
          </div>
          <div className="input-segment">
            <FormLabel>Password</FormLabel>
            <Input onKeyUp={enterHandler('password')} value={password} onChange={event => setPassword(event?.target?.value)} type="password" placeholder="Password" />
          </div>
          <div className="input-segment" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontSize: 12 }}>
            <a href="/forgot-password" style={{ color: '#aaaaff' }}>Forgot Password?</a>
          </div>
          <ButtonGroup style={{ paddingTop: 24, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Button onClick={submitSignin} tag="a" color="primary" wideMobile style={{ alignSelf: 'flex-end' }}>Submit</Button>
          </ButtonGroup>
          <OrBar style={{ marginTop: 60, marginBottom: 60 }} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <GoogleSignIn />
          </div>
        </form>
      </div>
    </section>
  );
}
