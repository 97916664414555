import memoizeObjectFunction from './memoizeObjectFunction';

const memoizeEnterPress = (callback) => {
  return memoizeObjectFunction((key, event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      callback(key);
    }
  });
}

export default memoizeEnterPress;
