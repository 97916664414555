import React, { useCallback, useState } from 'react';
import SectionHeader from '../components/sections/partials/SectionHeader';
import classNames from 'classnames';
import Button from '../components/elements/Button';
import FormLabel from '../components/elements/FormLabel';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';

function getQueryParams() {
  const params = {};
  const search = window.location.search.substring(1);
  const vars = search.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

export default function ResetPassword(props) {
  const {
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  } = props;

  const params = getQueryParams();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const [resultMessage, setResultMessage] = useState('');
  const [success, setSuccess] = useState(null);

  const executePasswordReset = useCallback(async () => {
    const response = await fetch('https://api.staticcast.com/v1/auth/actOnConfirmation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        updated_password: password,
        confirmation_value: getQueryParams().confirmation_value,
      }),
    });

    const responseBody = await response.text();

    if(response.status >= 200 && response.status < 400) {
      setSuccess(true);
    } else {
      setSuccess(false);
      setResultMessage(responseBody);
    }
  }, [password]);

  if (success === true) {
    return (
      <section className="hero section center-content">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100 }}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Password Reset Complete
          </h1>
          <p>You can login now</p>

          <Button tag="a" color="primary" wideMobile href="/login">
            Go To Login
          </Button>
        </div>
      </section>
    );
  }

  if (success === false) {
    return (
      <section className="hero section center-content">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100 }}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{ opacity: 1 }}>
            Reset Failure!
          </h1>
          <p>{resultMessage}</p>
          <Button onClick={() => {
            setSuccess(null);
            setResultMessage('');
          }}>Try Again</Button>
        </div>
      </section>
    );
  }

  const ready = (password === passwordConfirmation && password.length > 0);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '4em' }}>
            <SectionHeader data={{ title: 'Reset Password', paragraph: `Enter new password for ${params.email}` }} className="center-content" />

            <form className="med-form" style={{ padding: 20 }} data-reveal-delay="400">
              <div className="input-segment">
                <FormLabel>Password</FormLabel>
                <Input value={password} onChange={event => setPassword(event?.target?.value)} type="password" placeholder="Password" />
              </div>

              <div className="input-segment">
                <FormLabel>Confirm Password</FormLabel>
                <Input value={passwordConfirmation} onChange={event => setPasswordConfirmation(event?.target?.value)} type="password" placeholder="PasswordConfirmation" />
              </div>

              <ButtonGroup style={{ paddingTop: 24, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Button onClick={executePasswordReset} disabled={!ready} tag="a" color={ready ? 'primary' : 'dark'} wideMobile style={{ alignSelf: 'flex-end' }}>Submit</Button>
              </ButtonGroup>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
