import React from 'react';

export default function OrBar(props) {
  return (
    <div style={{ alignSelf: 'stretch', marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...props.style }}>
      <div style={{ flex: 1, height: 1, backgroundColor: '#fff' }}></div>
      <span style={{ marginLeft: 10, marginRight: 10 }}>or</span>
      <div style={{ flex: 1, height: 1, backgroundColor: '#fff' }}></div>
    </div>
  );
}
