export default function memoizeObjectFunction(func) {
  let storedFunctions = {};
  return (key, ...extras) => {
    if (!storedFunctions[key]) {
      storedFunctions[key] = (value) => {
        func(key, value, ...extras);
      };
    }
    return storedFunctions[key];
  };
}

export function memoizeExtensiveFunction(func) {
  let storedFunctions = {};
  return (key, ...extras) => {
    if (!storedFunctions[key]) {
      storedFunctions[key] = (...params) => {
        func({ key, extras, params });
      };
    }
    return storedFunctions[key];
  };
}
