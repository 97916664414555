import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import { submitSignup } from '../../business/api';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  // const [error, setError] = useState(null);

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  const submitEmailLead = useCallback(() => {
    const atDex = email.indexOf('@');
    const dotDex = email.indexOf('.');
    if (atDex === -1 || dotDex === -1 || dotDex < atDex) return alert('That email looks invalid, check your @ and . symbols');
    submitSignup({ email }).then(result => {
      if (result.ok) {
        setSubmitted(true);
      }
    });
  }, [email]);

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Have an employee in mind?
              </h3>
          </div>
          <div className="cta-action">
            {submitted && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <h3 style={{ color: '#fff', fontSize: 24, fontWeight: 600, margin: 0 }}>Submitted <span style={{ marginLeft: 12 }} role='img' aria-label='checkbox' >✅</span></h3>
            </div>}
            {!submitted && <Input onKeyPress={event => {
              if (event.key === 'Enter') submitEmailLead();
            }} value={email} onChange={event => setEmail(event.target.value)} id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Enter your email">
              <div onClick={submitEmailLead} style={{ cursor: 'pointer', position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: 30, height: 30, backgroundColor: '#5758dd', right: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5 }}>
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#fff" />
                </svg>
              </div>
            </Input>}
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
