import React, { useEffect, useRef } from 'react';
import { requestFromApi } from '../../business/api';
import { redirectToEntityDashboard } from '../../business/entityRedirection';

function loadScript(src) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  });
}

async function handleCredentialResponse(credentialResponse) {
  const response = await requestFromApi('v1/auth/finalizeGoogleAuth', { method: 'POST', body: { token: credentialResponse.credential } });

  const { entity, entity_type, token } = response?.json || {};
  redirectToEntityDashboard({ token, entity_type, entity });
}

export default function GoogleSignIn(props) {
  const googleButton = useRef(null);

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    const id = process?.env?.GOOGLE_OAUTH_CLIENT_ID || '6042588091-ef8f803ehvfgqipehb0otq9eclclqabq.apps.googleusercontent.com';

    loadScript(src).then(() => {
      // google is defined in the appended script
      console.log(window.google)
      window.google.accounts.id.initialize({
        client_id: id,
        callback: handleCredentialResponse,
      })
      window.google.accounts.id.renderButton(
        googleButton.current,
        { theme: 'outline', size: 'large' }
      )
    }).catch(console.error)

    return () => {
      // const scriptTag = document.querySelector(`script[src="${src}"]`)
      // if (scriptTag) document.body.removeChild(scriptTag)
      // I don't see any reason to _remove_ the script
    }
  }, []);

  return (
    <div ref={googleButton} {...props}></div>
  )
}
