import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      {/* <img style={{ position: 'absolute', top: 0, right: 0, height: 286, width: '100%' }} src={require('../../assets/images/illustration-section-01.svg')} alt="Hero" className="hero-decoration" /> */}
      <div className={innerClasses}>
        <div className="herohero hero-figure reveal-from-bottom illustration-element-01 hero-background" data-reveal-value="20px" data-reveal-delay="100">
          <div className="hero-overlay" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="hero-content">
              <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                Static Cast <span className="text-color-primary">Academy</span>
              </h1>
              <div className="container-xs">
                <p style={{ color: '#fafafa' }} className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Elevate your employees, elevate your business, we teach your employees how to program.
                </p>
                <div className="reveal-from-bottom" data-reveal-delay="600">
                  <ButtonGroup>
                    <Button tag="a" color="primary" wideMobile href="/signup">
                      Sign Up
                      </Button>
                    <Button tag="a" color="dark" wideMobile href="/login">
                      Login
                      </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
