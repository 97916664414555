import React, { useCallback, useState } from 'react';
import Button from '../components/elements/Button';
import ButtonGroup from '../components/elements/ButtonGroup';
import FormLabel from '../components/elements/FormLabel';
import Input from '../components/elements/Input';

export default function ForgotPassword(props) {
  const [email, setEmail] = useState('');

  const [resultMessage, setResultMessage] = useState('');
  const [success, setSuccess] = useState(null);

  const requestPasswordReset = useCallback(async () => {
    const response = await fetch('https://api.staticcast.com/v1/auth/requestPasswordReset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });

    const responseBody = await response.text();

    if(response.status >= 200 && response.status < 400) {
      setSuccess(true);
      // redirect to the relevant dashboard for the given user
      // administrator: admin.staticcast.com
      // affiliate: affiliate.staticcast.com
      // manager: manager.staticcast.com
      // user: app.staticcast.com
    } else {
      setSuccess(false);
      setResultMessage(responseBody);
    }
  }, [email]);

  if (success === true) {
    return (
      <section className="hero section center-content">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100 }}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Password Reset Submitted
          </h1>
          <p>Check your email</p>

          <Button tag="a" color="primary" wideMobile href="/">
            Return Home
          </Button>
        </div>
      </section>
    );
  }

  if (success === false) {
    return (
      <section className="hero section center-content">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100 }}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200" style={{ opacity: 1 }}>
            Reset Failure!
          </h1>
          <p>{resultMessage}</p>
          <Button onClick={() => {
            setSuccess(null);
            setResultMessage('');
          }}>Try Again</Button>
        </div>
      </section>
    );
  }

  return (
    <section className="hero section center-content">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h1 className="mt-0 mb-16 reveal-from-bottom" style={{ opacity: 1 }} data-reveal-delay="120">
          Forgotton <span className="text-color-primary">Password</span>
        </h1>
        <form className="med-form" style={{ padding: 20 }} data-reveal-delay="400">
          <div className="input-segment">
            <FormLabel>Email Address</FormLabel>
            <Input value={email} onChange={event => setEmail(event?.target?.value)} type="email" placeholder="Email" />
          </div>
          <div className="input-segment" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontSize: 12 }}>
            <a href="/login" style={{ color: '#aaaaff' }}>Back To Login</a>
          </div>
          <ButtonGroup style={{ paddingTop: 24, display: 'flex', flexDirection: 'column' }}>
            <Button onClick={requestPasswordReset} tag="a" color="primary" wideMobile style={{ alignSelf: 'flex-end' }}>Submit</Button>
          </ButtonGroup>
        </form>
      </div>
    </section>
  );
}
