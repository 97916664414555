import React, { useCallback, useState } from 'react';
import { submitSignup } from '../business/api';
import Button from '../components/elements/Button';
import ButtonGroup from '../components/elements/ButtonGroup';
import FormLabel from '../components/elements/FormLabel';
import Input from '../components/elements/Input';
import Select from '../components/elements/Select';

const states = [
  { code: 'AL', name: 'Alabama' },
  { code: 'AK', name: 'Alaska' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DE', name: 'Delaware' },
  { code: 'DC', name: 'District Of Columbia' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'IA', name: 'Iowa' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'ME', name: 'Maine' },
  { code: 'MD', name: 'Maryland' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MT', name: 'Montana' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NY', name: 'New York' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VT', name: 'Vermont' },
  { code: 'VA', name: 'Virginia' },
  { code: 'WA', name: 'Washington' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WY', name: 'Wyoming' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'GU', name: 'Guam' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'UM', name: 'United States Minor Outlying Islands' },
  { code: 'VI', name: 'Virgin Islands' },
  { code: 'AA', name: 'Armed Forces Americas' },
  { code: 'AE', name: 'Armed Forces Europe' },
  { code: 'AP', name: 'Armed Forces Pacific' },
  { code: 'XB', name: 'Baker Island' },
  { code: 'XH', name: 'Howland Island' },
  { code: 'XQ', name: 'Jarvis Island' },
  { code: 'XU', name: 'Johnston Atoll' },
  { code: 'XM', name: 'Kingman Reef' },
  { code: 'QM', name: 'Midway Islands' },
  { code: 'XV', name: 'Navassa Island' },
  { code: 'XL', name: 'Palmyra Atoll' },
  { code: 'QW', name: 'Wake Island' },
];

export default function SignUp(props) {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessState, setBusinessState] = useState('');
  const [studentQuantity, setStudentQuantity] = useState('');

  const [resultMessage, setResultMessage] = useState('');
  const [success, setSuccess] = useState(null);

  const submitSignupCallback = useCallback(async () => {
    const response = await submitSignup({ email, phone, businessName, businessState, studentQuantity });

    if(response.status >= 200 && response.status < 400) {
      setSuccess(true);
      setResultMessage('We will reach out soon!');
    } else {
      setSuccess(false);
      setResultMessage(response.text);
    }
  }, [email, phone, businessName, businessState, studentQuantity]);

  if (success === true) {
    return (
      <section className="hero section center-content">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100 }}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Thank you <span className="text-color-primary">{businessName}</span>
          </h1>
          <p>{resultMessage}</p>

          <Button tag="a" color="primary" wideMobile href="/">
            Return Home
          </Button>
        </div>
      </section>
    );
  }

  if (success === false) {
    return (
      <section className="hero section center-content">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100 }}>
          <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Signup Failure!
          </h1>
          <p>{resultMessage}</p>
          <Button onClick={() => {
            setSuccess(null);
            setResultMessage('');
          }}>Try Again</Button>
        </div>
      </section>
    );
  }

  return (
    <section className="hero section center-content">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
          Sign Up <span className="text-color-primary">Business</span>
        </h1>
        <form className="med-form" style={{ padding: 20 }} data-reveal-delay="400">
          <div className="input-segment">
            <FormLabel>Owner/Manager Email Address*</FormLabel>
            <Input value={email} onChange={event => setEmail(event?.target?.value)} type="email" placeholder="Email" />
          </div>
          <div className="input-segment">
            <FormLabel>Owner/Manager Phone Number</FormLabel>
            <Input value={phone} onChange={event => setPhone(event?.target?.value)} type="text" placeholder="Phone Number" />
          </div>
          <div className="input-segment">
            <FormLabel>Legal Business Name*</FormLabel>
            <Input value={businessName} onChange={event => setBusinessName(event?.target?.value)} type="text" placeholder="Business Name" />
          </div>
          <div className="input-segment">
            <FormLabel>US Business State</FormLabel>
            <Select value={businessState} onChange={event => setBusinessState(event?.target?.value)} placeholder="Business State">
              {states.map(item => <option key={item.code} value={item.code}>{item.name}</option>)}
            </Select>
          </div>
          <div className="input-segment">
            <FormLabel>How Many Students</FormLabel>
            <Input value={studentQuantity} onChange={event => setStudentQuantity(event?.target?.value)} type="number" placeholder="Quantity Of Students" />
          </div>
          <div className="input-segment" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontSize: 12 }}>
            <a style={{ color: '#aaaaff' }} href="/login">Login Instead</a>
          </div>
          <ButtonGroup style={{ paddingTop: 24, display: 'flex', flexDirection: 'column' }}>
            <Button onClick={submitSignupCallback} tag="a" color="primary" wideMobile style={{ alignSelf: 'flex-end' }}>Submit</Button>
          </ButtonGroup>
        </form>
      </div>
    </section>
  );
}
