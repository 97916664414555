export function encodeURIObject(obj) {
  if (!obj) return '';
  return Object.keys(obj).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])).join('&');
}

export function redirectToEntityDashboard({ entity, entity_type, token }) {
  if (entity_type === 'user' || entity_type === 'collaborator') {
    document.location.href = 'https://app.staticcast.com?' + encodeURIObject({ token, entity_type, email: entity?.email });
  // } else if (entity_type === 'administrator') {
  //   document.location.href = 'https://admin.staticcast.com?' + encodeURIObject({ token, entity_type, email: entity?.email });
  } else if (entity_type === 'affiliate') {
    document.location.href = 'https://affiliate.staticcast.com?' + encodeURIObject({ token, entity_type, email: entity?.email });
  }
}
