import { isPlainObject } from 'lodash';

let baseUrl = process.env.API_URL || 'https://api.staticcast.com';
// let baseUrl = 'http://localhost:9090';

export async function setBaseUrl(url) {
  baseUrl = url;
}

/**
 * @param {string} suburl
 * @param {RequestInit} options
 * @returns {{ status: number, ok: boolean, json: Object, text: string }}
 */
export async function requestFromApi(suburl, options = {}) {
  const url = `${baseUrl}/${suburl}`;
  if (!options.headers) options.headers = {};
  options.headers['Content-Type'] = 'application/json';
  options.headers.Accept = 'application/json';
  options.headers.Authorization = `Bearer ${options.token}`;
  if (options.body && isPlainObject(options.body)) {
    options.body = JSON.stringify(options.body);
  }
  const response = await fetch(url, options);

  const result = {};
  result.text = await response.text();

  try {
    result.json = JSON.parse(result.text);
  } catch (err) {
    result.json_parse_error = err;
  }

  result.status = response.status;
  result.ok = response.ok;
  return result;
}

export function validateToken(token) {
  return requestFromApi('v1/auth/checkToken', { token });
}

export function login({ email, password }) {
  return requestFromApi('v1/auth/authenticate', { method: 'POST', body: JSON.stringify({ email, password }) });
}


export function submitSignup({ email, phone, businessName, businessState, studentQuantity }) {
  return requestFromApi('v1/home/signup', {
    method: 'POST',
    body: {
      email,
      phone,
      businessName,
      businessState,
      studentQuantity,
    },
  });
}
